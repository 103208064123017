import ImgComponent from "../imgComponent"

const Partner = () => {
  return (
    <section
      className="elementor-element elementor-element-2d8234c elementor-section-stretched elementor-section-height-min-height elementor-section-items-top elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section"
      data-id="2d8234c"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row font-alamat">
          <div
            className="elementor-element elementor-element-29dade0 elementor-column elementor-col-50 elementor-top-column"
            data-id="29dade0"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <section
                  className="elementor-element elementor-element-dc2f41b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                  data-id="dc2f41b"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-element elementor-element-5020c74 elementor-column elementor-col-50 elementor-inner-column"
                        data-id="5020c74"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-2f58a94 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor--widget--image elementor-fixImg"
                              data-id="2f58a94"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatey":{"unit":"px","size":-100,"sizes":[]},"_position":"absolute","avante_ext_scrollme_scalex":{"unit":"px","size":1.2,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1.2,"sizes":[]},"avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image">
                                  {/* <img
																		width={683}
																		height={1024}
																		src={specialImg}
																		className="attachment-large size-large"
																		alt=""
																	/> */}
                                  <ImgComponent
                                    pmlImg="HomeContactUs.png"
                                    className="attachment-large size-large"
                                    id="imgPartnerContent1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a250a1b elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor--widget--image elementor-fixImg"
                              data-id="a250a1b"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatey":{"unit":"px","size":40,"sizes":[]},"_position":"absolute","avante_ext_scrollme_scalex":{"unit":"px","size":1.2,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1.2,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":-20,"sizes":[]},"avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image">
                                  {/* <img
																		width={1024}
																		height={1024}
																		src={specialImg2}
																		className="attachment-large size-large"
																		alt=""
																	/> */}
                                  <ImgComponent
                                    pmlImg="HomeContactUs2.png"
                                    className="attachment-large size-large"
                                    id="imgPartnerContent2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-605917d elementor-column elementor-col-50 elementor-inner-column"
                        data-id="605917d"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-4553030 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor--widget--image elementor-fixImg"
                              data-id={4553030}
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":60,"sizes":[]},"_position":"absolute","avante_ext_scrollme_scalex":{"unit":"px","size":1.2,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1.2,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":-40,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image">
                                  {/* <img
																		width={1024}
																		height={1024}
																		src={specialImg3}
																		className="attachment-large size-large"
																		alt=""
																	/> */}
                                  <ImgComponent
                                    pmlImg="HomeContactUs3.png"
                                    className="attachment-large size-large"
                                    id="imgPartnerContent2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {/* tes */}
          <div
            className="elementor-element elementor-element-c1f4bbc elementor-column elementor-col-50 elementor-top-column"
            data-id="c1f4bbc"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap padding-partner">
                <div
                  className="elementor-element elementor-element-712fadd elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="712fadd"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      We are here for you to work together
                    </h2>
                  </div>
                </div>
                <section
                  className="elementor-element elementor-element-f06533f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                  data-id="f06533f"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-element elementor-element-713b919 elementor-column elementor-col-50 elementor-inner-column"
                        data-id="713b919"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-7731173 elementor-widget elementor-widget-heading"
                              data-id={7731173}
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-heading-title elementor-size-default">
                                  Address
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2ed0650 elementor-widget elementor-widget-heading"
                              data-id="2ed0650"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container ">
                                <span
                                  className="elementor-heading-title elementor-size-default"
                                  id="font-home-address"
                                >
                                  Gedung Indra Sentral Unit R & T
                                  <br />
                                  Jl. Let. Jend. Suprapto No. 60 Cempaka Putih{" "}
                                  <br />
                                  Jakarta Pusat 10520, Indonesia
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-c4a55c8 elementor-column elementor-col-50 elementor-inner-column"
                        data-id="c4a55c8"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-6c41735 elementor-widget elementor-widget-heading"
                              data-id="6c41735"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-heading-title elementor-size-default">
                                  Contact
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-80757ef elementor-widget elementor-widget-heading"
                              data-id="80757ef"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <span
                                  className="elementor-heading-title elementor-size-default"
                                  id="font-home-address"
                                >
                                  +(6221) 426 5310
                                  <br />
                                  +(6221) 426 9475
                                  <br />
                                  info@pharmametricslabs.com
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="elementor-element elementor-element-a688d51 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button"
                  data-id="a688d51"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_smoove_duration":1000,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="button.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-button-wrapper">
                      <a
                        href="/HelpCenter/ContactUs"
                        className="elementor-button-link elementor-button elementor-size-md"
                        role="button"
                      >
                        <span className="elementor-button-content-wrapper">
                          <span className="elementor-button-text">
                            Get In Touch
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partner
