import { Instagram } from "react-feather"
import InstagramSlider from "../../sliders/InstagramSlider"

const InstagramFeed = ({ instagramContent }) => {
  return (
    <section className="slider" style={{ marginBottom: "2em" }}>
      <div className="container">
        <h2 style={{ fontWeight: "700" }}>Follow our Instagram on</h2>
        <a href="https://www.instagram.com/pharmametriclabs/" target="_blank">
          <h2 style={{ color: "#00623D", fontWeight: "400" }}>
            @pharmametriclabs
          </h2>
        </a>
        <InstagramSlider contents={instagramContent.nodes} />
        <button className="btnVisitIG">
          <Instagram size={16} />
          Visit our Instagram
        </button>
      </div>
    </section>
  )
}

export default InstagramFeed
