import React from "react"
// import logoPML from '../../../images/logoPML.png';

const Header = () => {
  return (
    <React.Fragment>
      <section
        className="elementor-element elementor-element-29ca933 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-element elementor-element-74ee551 elementor-column elementor-col-80 elementor-top-column"
              data-element_type="column"
              // style={{ width: '65%' }}
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-01f3d0b elementor-icon-list--layout-inline elementor-mobile-align-center elementor-tablet-align-center elementor-widget elementor-widget-icon-list"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items elementor-inline-items">
                        <li className="elementor-icon-list-item">
                          <div class="elementor-image">
                            <a data-elementor-open-lightbox="" href="/">
                              <img
                                width="150"
                                height="50"
                                src={"/logoPML.png"}
                                class="attachment-full size-full sizeLogo"
                                alt=""
                                style={{ width: "200px" }}
                              />
                            </a>
                          </div>
                        </li>
                        <li className="elementor-icon-list-item padding-top-navbar">
                          <a href="tel:(+6221)4265310">
                            <span className="elementor-icon-list-icon2 paddingIcon">
                              <i
                                aria-hidden="true"
                                className="fas fa-phone-alt"
                              />
                            </span>
                            <span className="elementor-icon-list-text paddingText">
                              (+6221) 426 5310
                            </span>
                          </a>
                        </li>
                        <li className="elementor-icon-list-item padding-top-navbar">
                          <a href="info@pharmametriclabs.com">
                            <span className="elementor-icon-list-icon2 paddingIcon">
                              <i
                                aria-hidden="true"
                                className="far fa-envelope"
                              />
                            </span>
                            <span className="elementor-icon-list-text paddingText">
                              info@pharmametriclabs.com
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Header
