import img1 from "../../../../images/BPOM_logo.png"
import img4 from "../../../../images/ISO_logo.png"
import img3 from "../../../../images/KAN_logo.png"
import img2 from "../../../../images/NPRA_logo.png"

const aboutUs = () => {
  return (
    <section
      className="elementor-element elementor-element-fb8d1c3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section padding-all-aboutus-home"
      data-id="fb8d1c3"
      data-element_type="section"
    >
      <div className="container text-center">
        <h2 className="latest-news-title">Why Choose Us</h2>
      </div>
      <div className="elementor-container elementor-column-gap-default padding-home-about">
        <div className="elementor-row ">
          <div
            className="elementor-element elementor-element-8601bb1 elementor-column elementor-col-33 elementor-top-column elementor-AboutUs"
            data-id="8601bb1"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-118d518 elementor-widget elementor-widget-heading mt-4"
                  data-id="118d518"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div
                    className="elementor-widget-container"
                    id="home-aboutus-konten-jsv"
                  >
                    <span className="elementor-heading-title elementor-size-default">
                      <ol>
                        <li>High commitment to quality and integrity</li>
                        <li>Customer-oriented service at competitive cost</li>
                        <li>Effective project management</li>
                        <li>
                          Highly experienced and qualified staff comply to
                          ICH-GCP guidelines
                        </li>
                        <li>Updated and accredited laboratory</li>
                        <li>Full technical support for customers</li>
                        <li>Good networking with pharmaceutical industry</li>
                        <li>
                          Collaborative network with other clinical research
                          center
                        </li>
                      </ol>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-7bc3d66 elementor-column elementor-col-33 elementor-top-column elementor-AboutUs"
            data-id="7bc3d66"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated ">
              <div className="elementor-widget-wrap padding-home-aboutus">
                <div
                  className="elementor-element elementor-element-118d518 elementor-widget elementor-widget-heading"
                  data-id="118d518"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <span className="elementor-heading-title elementor-size-default">
                      Pharma Metric Labs is certified with:
                    </span>
                  </div>

                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-element elementor-element-3993aea elementor-column elementor-col-8 elementor-top-column"
                        data-id="3993aea"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-6bb69bf elementor-widget__width-auto elementor-widget elementor--widget--image"
                              data-id="6bb69bf"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image darya-padding">
                                  <img
                                    width={254}
                                    height={300}
                                    src={img1}
                                    className="attachment-full size-full"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-3993aea elementor-column elementor-col-8 elementor-top-column"
                        data-id="3993aea"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-6bb69bf elementor-widget__width-auto elementor-widget elementor--widget--image"
                              data-id="6bb69bf"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image darya-padding">
                                  <img
                                    width={254}
                                    height={300}
                                    src={img2}
                                    className="attachment-full size-full"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-3993aea elementor-column elementor-col-8 elementor-top-column"
                        data-id="3993aea"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-6bb69bf elementor-widget__width-auto elementor-widget elementor--widget--image"
                              data-id="6bb69bf"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image darya-padding">
                                  <img
                                    width={254}
                                    height={300}
                                    src={img3}
                                    className="attachment-full size-full"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-3993aea elementor-column elementor-col-8 elementor-top-column"
                        data-id="3993aea"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-6bb69bf elementor-widget__width-auto elementor-widget elementor--widget--image"
                              data-id="6bb69bf"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.2,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image darya-padding">
                                  <img
                                    width={254}
                                    height={300}
                                    src={img4}
                                    className="attachment-full size-full"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-a1fa399 elementor-column elementor-col-33 elementor-top-column elementor-AboutUs "
            data-id="a1fa399"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap padding-home-aboutus">
                <div
                  className="elementor-element elementor-element-4019abe elementor-widget elementor-widget-heading"
                  data-id="4019abe"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div
                    className="elementor-widget-container"
                    id="home-aboutus-konten2"
                  >
                    <span className="elementor-heading-title elementor-size-default">
                      BE study report has been accepted by:
                      <br />
                      - BPOM (Indonesia FDA)
                      <br />
                      - BPFK (Malaysia FDA)
                      <br />
                      - BFAD (Philippines FDA)
                      <br />
                      - VDA (Vietnam FDA)
                      <br />
                      - CDDA (Sri Lanka FA)
                      <br />- Nigeria FDA
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default aboutUs
