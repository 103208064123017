import React from "react"
import AboutUs from "../content/Home/AboutUs"
import Banner from "../content/Home/Banner"
import GoalsContent from "../content/Home/GoalsContent"
import InstagramFeed from "../content/Home/InstagramFeed"
import Partner from "../content/Home/PartnerContent"
import Quotes from "../content/Home/Quotes"
import UpdatedNews from "../content/Home/UpdatedNews"
import Mobile from "../content/Mobile"
import Footer from "../layout/Footer"
import SEO from "../SEO"
import "./cssberhasil"
import Header from "./Header"
import Navbar from "./Navbar"

import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import JSberhasil from "./jsberhasil"

const LayoutHome = () => {
  const query = useStaticQuery(graphql`
    {
      instagram: allInstagramContent(limit: 10) {
        nodes {
          localImage {
            childImageSharp {
              gatsbyImageData(height: 250, width: 250, layout: CONSTRAINED)
            }
          }
          permalink
          caption
        }
      }
      news: allContentfulNewsEvents(sort: { order: DESC, fields: date }) {
        nodes {
          id
          title
          slug
          date(formatString: "DD MMMM YYYY")
          shortDesc
          body {
            raw
            references {
              gatsbyImageData
            }
          }
          thumbnail {
            gatsbyImageData(
              placeholder: TRACED_SVG
              width: 1024
              height: 576
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  `)

  return (
    // <>
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074 testWrap",
        }}
      />
      <SEO
        title="Pharmametrics Lab: World Class CRO Companies in Indonesia"
        description="A Leading Contract Research Organization (CRO) providing contract services to pharmaceutical companies in ASEAN with over 10 years of experiences"
      />

      <div id="perspective">
        <Mobile />
        <div
          id="wrapper"
          className="hasbg transparent"
          style={{ paddingTop: "0px" }}
        >
          <div id="elementor-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="3141"
              className="elementor elementor-3141"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Header />
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" className="wide">
            <div className="inner">
              <div className="inner-wrapper">
                <div className="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    className="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <Banner />
                        <Quotes />
                        <GoalsContent />
                        <AboutUs />
                        <UpdatedNews newsContent={query.news} />
                        <InstagramFeed instagramContent={query.instagram} />
                        <Partner />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
      {/* </> */}
    </React.Fragment>
  )
}

export default LayoutHome
